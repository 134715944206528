/* global fetch */

export const FETCH_CARD_BEGIN = 'FETCH_CARD_BEGIN';
export const fetchCardBegin = () => ({
	type: FETCH_CARD_BEGIN
});

export const FETCH_CARD_SUCCESS = 'FETCH_CARD_SUCCESS';
export const fetchCardSuccess = (card, mediasCount) => ({
	type: FETCH_CARD_SUCCESS,
	payload: { card, mediasCount }
});

export const FETCH_CARD_FAILURE = 'FETCH_CARD_FAILURE';
export const fetchCardFailure = error => ({
	type: FETCH_CARD_FAILURE,
	payload: error
});

export function fetchCard(cardCode) {
	return (dispatch) => {
		dispatch(fetchCardBegin());
		return fetch(`${process.env.ROVERPIX_API}/api/v1/card/${cardCode}/medias`)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(fetchCardFailure(json.message));
				} else {
					dispatch(fetchCardSuccess(json.card, json.medias_count));
				}
			}).catch((e) => {
				dispatch(fetchCardFailure(e));
			});
	};
}

export function fetchCardCustomer(customer) {
	return (dispatch) => {
		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/customer/card/medias`,
			{
				method: 'post',
				headers: {'Content-type': 'application/json'},
				body: JSON.stringify(customer)
			}
		);
		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(fetchCardFailure(json.error));
				} else {
					dispatch(fetchCardSuccess(json.card, json.medias_count));
				}
			}).catch((e) => {
				dispatch(fetchCardFailure(e));
			});
	};
}

export const CLEAR_CARD = 'CLEAR_CARD';
export const clearCard = () => ({
	type: CLEAR_CARD,
});

