import React, { useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Form from '../../Atoms/Form';
import Input from '../../Atoms/Input';
import Calendar from '../../Atoms/Calendar';
import Button from '../../Atoms/Button';

import './index.sass';

const GetCustomerForm = ({ className, loading, onSubmit, intl }) => {
	const [name, setName] = useState('')
	const [lastname, setLastname] = useState('')
	const [customerDate, onChangeCustomerDate] = useState(new Date());
	const { formatMessage } = intl;
	const messages = defineMessages({
		name: {
			id: 'GetCustomerForm.name',
			defaultMessage: 'Nome'
		},
		lastname: {
			id: 'GetCustomerForm.lastname',
			defaultMessage: 'Sobrenome'
		},
		search: {
			id: 'GetCustomerForm.search',
			defaultMessage: 'Pesquisar'
		}
	});

	return (
		<Form
			className={`GetCustomerForm ${className}`}
			onSubmit={(e) => {
				e.preventDefault()
				onSubmit({name, lastname, customerDate})
			}}
		>
			<Input
				id="name"
				className="GetCustomerForm__input"
				type="text"
				label={formatMessage(messages.name)}
				name="name"
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>
			<Input
				id="lastname"
				className="GetCustomerForm__input"
				type="text"
				label={formatMessage(messages.lastname)}
				name="lastname"
				value={lastname}
				onChange={(e) => setLastname(e.target.value)}
			/>
			<Calendar
				value={customerDate}
				onChange={onChangeCustomerDate}
			/>
			<Button type="submit">
				{formatMessage(messages.search)}
			</Button>
		</Form>
	)
}

GetCustomerForm.propTypes = {
	className: PropTypes.string,
	cardCode: PropTypes.string,
	loading: PropTypes.bool,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func
};

GetCustomerForm.defaultProps = {
	className: '',
	cardCode: '',
	loading: false,
	onChange: () => {},
	onSubmit: () => {}
};

export default injectIntl(GetCustomerForm);
