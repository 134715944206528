import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import Text from '../../Atoms/Text'
import CheckBox from '../../Atoms/CheckBox'
import './index.sass';

const AllDigitalPackage = ({ className, productPrice, isAdded, onChange, intl }) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		allPhotos: {
			id: 'allDigitalPackage.allPhotos',
			defaultMessage: 'Todas as fotos'
		}
	});

	return (
		<div className={`AllDigitalPackage ${className}`} onClick={onChange}>
			<div className="AllDigitalPackage__items AllDigitalPackage__checkbox">
				<CheckBox
					label={formatMessage(messages.allPhotos)}
					checked={isAdded}
				/>
				<Text className="AllDigitalPackage__items__text AllDigitalPackage__items__text__value">
					R$ {productPrice}
				</Text>
			</div>
		</div>
	);
};

AllDigitalPackage.propTypes = {
	className: PropTypes.string,
	productPrice: PropTypes.number,
	isAdded: PropTypes.bool,
	onChange: PropTypes.func,
	intl: PropTypes.shape().isRequired
};

AllDigitalPackage.defaultProps = {
	className: '',
	productPrice: 0.0,
	isAdded: false,
	onChange: () => {}
};

export default injectIntl(AllDigitalPackage);
