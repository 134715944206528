import React from 'react';
import PropTypes from 'prop-types';
import * as MaterialCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './index.sass';

 const Checkbox = ({ label, required, checked, onChange }) => {
	const OrangeCheckbox = withStyles({
		root: {
			color: '#ffffff',
			'&$checked': {
				color: '#F57C00',
			},
		},
		checked: {},
	})(props => <MaterialCheckbox.default color="default" {...props} />);

	return (
		<div className="Checkbox">
			<FormControlLabel
				control={
					<OrangeCheckbox
						checked={checked}
						required={required}
						onChange={(e) => onChange(e.target.checked)}
						value="checked"
					/>
				}
				label={label}
			/>
		</div>
	);
}

Checkbox.propTypes = {
	checked: PropTypes.bool,
	required: PropTypes.bool,
	onChange: PropTypes.func
};

Checkbox.defaultProps = {
	checked: false,
	required: false,
	onChange: () => {}
};

export default Checkbox;
