import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import IconButton from '../../Atoms/IconButton';
import Input from '../../Atoms/Input';
import Text from '../../Atoms/Text';
import Button from '../../Atoms/Button';

import './index.sass';

import {ReactComponent as CopyIcon} from '../../../assets/icons/copy.svg';
import PixPagarme from '../../../assets/images/pix_pagarme.png';

const PixForm = ({ loading, orderId, pixText, error, onPay, onCheckPayment, intl }) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		title: {
			id: 'pixForm.title',
			defaultMessage: 'Pague utilizando o QRCode ou o método PIX copia e cola.'
		},
		description: {
			id: 'pixForm.description',
			defaultMessage: 'Após efetuar o pagamento, verifique utilizando o botão mais abaixo.'
		},
		copyAndPastePix: {
			id: 'pixForm.copyAndPastePix',
			defaultMessage: 'PIX copia e cola'
		},
		generateCode: {
			id: 'pixForm.generateCode',
			defaultMessage: 'Gerar código de pagamento'
		},
		or: {
			id: 'pixForm.or',
			defaultMessage: 'ou'
		},
		verifyPayment: {
			id: 'pixForm.verifyPayment',
			defaultMessage: 'Verificar pagamento'
		}
	});

	return (
		<div className="PixForm">
			<img
				className="PixForm__banner"
				alt="Pix banner"
				src={PixPagarme}
			/>
			{error && (
				<Text className="PixForm__error" variant="caption">
					{error}
				</Text>
			)}
			{pixText ? (
				<>
					<div>
						<Text variant="body2">
							{formatMessage(messages.title)}
						</Text>
						<Text variant="caption">
							{formatMessage(messages.description)}
						</Text>
					</div>
					<img
						className="PixForm__qrcode"
						alt="Pix qrcode"
						src={`${process.env.ROVERPIX_API}/api/v1/payment/${orderId}/pix_qrcode`}
					/>
					<div className="PixForm__or">
						<div className="PixForm__or__line" />
						<Text variant="body2">
							{formatMessage(messages.or)}
						</Text>
						<div className="PixForm__or__line" />
					</div>
					<div className="PixForm__copy">
						<Input
							id="PixCode"
							value={pixText}
							label={formatMessage(messages.copyAndPastePix)}
						/>
						<IconButton
							onClick={() => {
								var copyText = document.querySelector("#PixCode");
								copyText.select();
								copyText.setSelectionRange(0, 99999);
								document.execCommand("copy");
							}}
						>
							<CopyIcon fill="white"/>
						</IconButton>
					</div>
					<Button
						className="PixForm__generate-code"
						color="secondary"
						loading={loading}
						size="medium"
						onClick={onCheckPayment}
					>
						{formatMessage(messages.verifyPayment)}
					</Button>
				</>
			) : (
				<Button
					className="PixForm__generate-code"
					color="secondary"
					loading={loading}
					size="medium"
					onClick={onPay}
				>
					{formatMessage(messages.generateCode)}
				</Button>
			)}
		</div>
	)
}

PixForm.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.string,
	onPay: PropTypes.func,
	intl: PropTypes.shape().isRequired
}

PixForm.defaultProps = {
	loading: false,
	error: '',
	onPay: () => {}
}

export default injectIntl(PixForm);
