import React from 'react';
import PropTypes from 'prop-types';
import {Calendar as ReactCalendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './index.sass';

const Calendar = ({ className, value, onChange }) => (
	<ReactCalendar
		className={`Calendar ${className}`}
		locale="pt-BR"
		value={value}
		onChange={onChange}
	/>
)

Calendar.propTypes = {
	className: PropTypes.string,
	value: PropTypes.instanceOf(Date),
	onChange: PropTypes.func
};

Calendar.defaultProps = {
	className: '',
	value: new Date(),
	onChange: () => {}
}

export default Calendar;
