import React from 'react';

import './index.sass';

const MainTemplate = ({ children }) => (
	<div className="MainTemplate">
		{children}
	</div>
);

export default MainTemplate;