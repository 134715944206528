import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import './index.sass';

const Text = ({
	className, children, variant, paragraph, align
}) => (
	<Typography
		className={`Text ${className}`}
		variant={variant}
		paragraph={paragraph}
		align={align}
		color="textSecondary"
	>
		{children}
	</Typography>
);

Text.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	variant: PropTypes.oneOf([
		'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline', 'srOnly', 'inherit'
	]),
	align: PropTypes.oneOf([
		'inherit', 'left', 'center', 'right', 'justify'
	]),
	paragraph: PropTypes.bool
};

Text.defaultProps = {
	className: '',
	children: null,
	variant: 'body1',
	align: 'inherit',
	paragraph: false
};

export default Text;
