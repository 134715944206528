import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';
import { connect } from 'react-redux';

import './index.sass';

import Modal from '../../Atoms/Modal';
import Button from '../../Atoms/Button';
import Text from '../../Atoms/Text';
import PriceList from '../../Molecules/PriceList';

class ProductModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [
				{
					name: '0 foto',
					price: '30'
				},
				{
					name: '02 fotos',
					price: '60'
				},
				{
					name: '03 fotos',
					price: '90'
				},
				{
					name: 'foto adicional',
					price: '10'
				}
			]
		};
	}

	render() {
		const { className, isOpened, onClose, intl, products = {} } = this.props;
		const { dinamic = {} } = products;
		const { ranges_price = [] } = dinamic ? dinamic : {};
		const {formatMessage} = intl;
		const messages = defineMessages({
			help: {
				id: 'gallery.header.help',
				defaultMessage: 'Ajuda'
			},
			button: {
				id: 'productModal.button',
				defaultMessage: 'Entendi'
			},
			photos: {
				id: 'productModal.photos',
				defaultMessage: '{photos, plural, one {# foto} other {# fotos}}'
			},
			additionalPhoto: {
				id: 'productModal.additionalPhoto',
				defaultMessage: 'Foto adicional'
			}
		});
		const rangeSize = ranges_price.length > 0 ? ranges_price[ranges_price.length - 1].max_photos + 1 : 0;
		const ranges = [];
		const getRangeInfo = (index) => {
			let range = ranges_price.find((r) => (r.min_photo <= index || r.max_photos >= index))
			if (!range) range = ranges_price[ranges_price.length - 1];

			let price = 0
			let name = ''
			if (index >= rangeSize) {
				price = range.price
				name = formatMessage(messages.additionalPhoto)
			} else {
				price = ranges.length > 0 ? ranges[ranges.length - 1].price : 0
				price = price + range.price
				name = formatMessage(messages.photos, {photos: index})
			}

			return {name: name, price: price, id: range.id}
		}
		for (let i=1; i<=rangeSize; i++) {
			ranges.push(getRangeInfo(i))
		}

		return (
			<Modal
				className={`ProductModal ${className}`}
				isOpened={isOpened}
				onClose={onClose}
			>
				<div className="ProductModal__title">
					<Text className="ProductModal__title__text" variant="caption">
						{formatMessage(messages.help)}
					</Text>
				</div>
				<div className="ProductModal__table">
					{ranges.map(({id, name, price}, index) => (
						<PriceList
							productName={name}
							productPrice={price}
							key={`${id}-${index}`}
						/>
					))}
				</div>
				<div className="ProductModal__action">
					<Button
						className="ProductModal__action__button"
						color="primary"
						isLink
						loading={false}
						onClick={onClose}
						size="medium"
					>
						{formatMessage(messages.button)}
					</Button>
				</div>
			</Modal>
		);
	}
}

ProductModal.propTypes = {
	className: PropTypes.string,
	isOpened: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	onClose: PropTypes.func,
	products: PropTypes.shape({
		dinamic: PropTypes.shape({
			price: PropTypes.number
		})
	})
};

ProductModal.defaultProps = {
	className: '',
	isOpened: false,
	products: {},
	onClose: () => {},
};

const mapStateToProps = state => ({
	products: state.products
});

export default connect(mapStateToProps, null)(injectIntl(ProductModal));
