export const DO_PAYMENT_CHECKOUT_BEGIN = 'DO_PAYMENT_CHECKOUT_BEGIN';
export const doPaymentCheckoutBegin = () => ({
	type: DO_PAYMENT_CHECKOUT_BEGIN
});

export const DO_PAYMENT_CHECKOUT_SUCCESS = 'DO_PAYMENT_CHECKOUT_SUCCESS';
export const doPaymentCheckoutSuccess = (status) => ({
	type: DO_PAYMENT_CHECKOUT_SUCCESS,
	payload: status
});

export const DO_PAYMENT_CHECKOUT_FAILURE = 'DO_PAYMENT_CHECKOUT_FAILURE';
export const doPaymentCheckoutFailure = error => ({
	type: DO_PAYMENT_CHECKOUT_FAILURE,
	payload: error
});

export const DO_PAYMENT_PIX_CHECKOUT_SUCCESS = 'DO_PAYMENT_PIX_CHECKOUT_SUCCESS';
export const doPaymentPixCheckoutSuccess = ({status, pix_text: pixText}) => ({
	type: DO_PAYMENT_PIX_CHECKOUT_SUCCESS,
	payload: {status, pixText}
});

export function doPaymentCheckout(orderId, cardHash) {
	return (dispatch) => {
		dispatch(doPaymentCheckoutBegin());
		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/payment/checkout/${orderId}`,
			{
				method: 'post',
				headers: {'Content-type': 'application/json'},
				body: JSON.stringify({card_hash: cardHash})
			}
		);
		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(doPaymentCheckoutFailure(json.error));
				} else {
					dispatch(doPaymentCheckoutSuccess(json.status));
				}
			}).catch((e) => {
				dispatch(doPaymentCheckoutFailure(e.toString()));
			});
	};
}

export function doPaymentPixCheckout(orderId) {
	return (dispatch) => {
		dispatch(doPaymentCheckoutBegin());
		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/payment/checkout/${orderId}`,
			{
				method: 'post',
				headers: {'Content-type': 'application/json'},
				body: JSON.stringify({pix: true})
			}
		);
		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(doPaymentCheckoutFailure(json.error));
				} else {
					dispatch(doPaymentPixCheckoutSuccess(json));
				}
			}).catch((e) => {
				dispatch(doPaymentCheckoutFailure(e.toString()));
			});
	};
}

export const GET_PAYMENT_BEGIN = 'GET_PAYMENT_BEGIN';
export const getPaymentBegin = () => ({
	type: GET_PAYMENT_BEGIN
});

export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const getPaymentSuccess = (payment) => ({
	type: GET_PAYMENT_SUCCESS,
	payload: payment
});

export const GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE';
export const getPaymentFailure = error => ({
	type: GET_PAYMENT_FAILURE,
	payload: error
});

export function getPayment(orderId) {
	return (dispatch) => {
		dispatch(getPaymentBegin());
		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/payment/${orderId}`,
			{
				method: 'get'
			}
		);
		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(getPaymentFailure(json.error));
				} else {
					dispatch(getPaymentSuccess(json));
				}
			}).catch((e) => {
				dispatch(getPaymentFailure(e.toString()));
			});
	};
}
