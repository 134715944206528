import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {defineMessages, injectIntl} from 'react-intl';
import {LocalStorage, Analytics} from '../../../shared';

import Background from '../../Atoms/Background';
import Logo from '../../Atoms/Logo';
import Text from '../../Atoms/Text';
import Button from '../../Atoms/Button';
import Link from '../../Atoms/Link';
import Card from '../../Atoms/Card';
import Helmet from '../../Organisms/Helmet';
import SendPhotoForm from '../../Organisms/SendPhotoForm';
import Grid from '../../Molecules/Grid';
import LanguageChooser from '../../Molecules/LanguageChooser';
import CardForm from '../../Molecules/CardForm';
import GetCustomerForm from '../../Molecules/GetCustomerForm';

import {fetchCard, fetchCardCustomer,clearMedias, clearCard} from '../../../store/actions';

import './index.sass';

/* global document */
/* global window */

class HomeTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidCard: false,
			cardCode: '',
			sendingPhoto: false
		};

		this.parseCard = this.parseCard.bind(this);
	}

	componentDidMount() {
		const {clearCard} = this.props;

		clearCard();
		this.getCardFromUrl()
	}

	componentDidUpdate(prevProps) {
		const {sendingPhoto} = prevProps;

		if (sendingPhoto) return;

		const { card: cardState } = this.props;
		const { card = {}, error, loading, mediasCount } = cardState;
		const { id, card: cardCode } = card;
		const { history } = this.props;

		if (!error && !loading && id && mediasCount > 0) {
			history.push('/gallery/'+cardCode);
		}

		if (this.cardInput) this.cardInput.input.focus();
	}

	getCardFromUrl = () => {
		const {history} = this.props;
		const search = new URLSearchParams(history.location.search);
		const card = search.get('card');

		if (card && card.length === 9) {
			this.setState({
				cardCode: `${card.substring(0, 2)}-${card.substring(2, 6)}-${card.substring(6, 9)}`
			}, () => this.parseCard());
		}
	}

	setInvalidCard(status = false) {
		this.setState({
			invalidCard: status
		});
	}

	parseCard(e = null) {
		if (e) {
			e.preventDefault();
		}

		let {cardCode} = this.state;
		cardCode = cardCode.replace(/[-,_]/g, '').toUpperCase();

		if (cardCode.length < 9) {
			this.setInvalidCard(true);
		} else {
			this.setInvalidCard(false);
			this.readCard(cardCode)
		}
	}

	readCard = (cardCode) => {
		const { fetchCard, clearMedias } = this.props;

		clearMedias();
		fetchCard(cardCode);
		this.setState({
			cardCode: cardCode
		});
		this.sendCardReadEvent();
	}

	sendCardReadEvent = () => {
		const {organization: orgState} = this.props;
		const {organization = {}} = orgState;
		const {name} = organization;

		Analytics.sendEvent('card_read', 'browsing', name);
	}

	searchCustomer = (customer) => {
		const { fetchCardCustomer, clearMedias } = this.props;

		clearMedias();
		fetchCardCustomer(customer);
	}

	render() {
		const {card: cardState} = this.props;
		const {card, error, loading, mediasCount} = cardState;
		const {organization: orgState} = this.props;
		const {organization = {}} = orgState;
		const {name, web_name, contact_email, features = {}, features_organization = {}} = organization;
		const {site = {}} = features || {};
		const {can_send_photo: canSendPhoto} = site;
		const {webapp_initial_form: webappInitialForm} = features_organization || {};
		const {cardCode, invalidCard, sendingPhoto} = this.state;
		const {intl} = this.props;
		const {formatMessage} = intl;
		const emptyCard = Object.keys(card).length > 0 && mediasCount === 0;
		const messages = defineMessages({
			title: {
				id: 'home.title',
				defaultMessage: 'Veja aqui suas fotos mágicas!'
			},
			subtitle: {
				id: 'home.subtitle',
				defaultMessage: 'Digite o código do seu cartão-fotografia.'
			},
			warning: {
				id: 'home.footer.warning',
				defaultMessage: 'Suas fotos podem demorar até 48 horas para ficarem disponíveis.'
			},
			contact: {
				id: 'home.footer.contact',
				defaultMessage: 'Em caso de problemas, envie email para:'
			},
			button: {
				id: 'home.button',
				defaultMessage: 'Carregar cartão'
			},
			invalidCard: {
				id: 'home.card.invalid',
				defaultMessage: 'Cartão inválido'
			},
			cardNotFound: {
				id: 'home.card.notFound',
				defaultMessage: 'Cartão inválido'
			},
			cardEmptyPhoto: {
				id: 'home.card.emptyPhoto',
				defaultMessage: 'Não há fotos disponíveis para este cartão'
			},
			cardGenericError: {
				id: 'home.card.genericError',
				defaultMessage: 'Algo de errado aconteceu'
			},
			sendPhotos: {
				id: 'home.sendPhotos',
				defaultMessage: 'Enviar fotos'
			}
		});
		const getErrorMessage = (error) => {
			switch (error) {
			case 'CARD_NOT_FOUND':
				return formatMessage(messages.cardNotFound);
			case 'CARD_EMPTY_PHOTO':
				return formatMessage(messages.cardEmptyPhoto);
			default:
				return formatMessage(messages.cardGenericError);
			}
		};
		const getButtonMessage = () => {
			if (invalidCard) {
				return formatMessage(messages.invalidCard);
			}

			if (error) {
				return getErrorMessage(error);
			}

			if (emptyCard) {
				return formatMessage(messages.cardEmptyPhoto);
			}

			return formatMessage(messages.button);
		};
		const handleChangeLanguage = (value) => {
			const currentValue = LocalStorage.getItem('locale');

			if (currentValue !== value) {
				Analytics.sendEvent(`change_language_to_${value}`, 'browsing', name);
				LocalStorage.setItem('locale', value);
				document.location.reload(true);
			}
			this.cardInput.input.focus();
		};

		return (
			<div className="Home">
				<Helmet />
				<Background name={web_name} />
				<Grid type="container" justify="center">
					<Grid lg={4} md={4} sm={6} xs={10}>
						<Logo className="Home__logo" name={web_name} />
						<Text className="Home__title" variant="h2" paragraph>
							{formatMessage(messages.title)}
						</Text>
						{canSendPhoto && sendingPhoto ? (
							<SendPhotoForm
								onClose={(cardToShow) => {
									this.setState({
										sendingPhoto: false
									});

									if (cardToShow) this.readCard(cardToShow);
								}}
							/>
						) : (
							<div>
								<Text className="Home__subtitle" variant="subtitle1" paragraph>
									{formatMessage(messages.subtitle)}
								</Text>
								{webappInitialForm && webappInitialForm === 'customer' ? (
									<GetCustomerForm
										onSubmit={this.searchCustomer}
									/>
								) : (
									<CardForm
										cardCode={cardCode}
										loading={loading}
										buttonMessage={getButtonMessage()}
										hasError={invalidCard || error || emptyCard}
										onChange={(e) => {
											this.setState({
												cardCode: e.target.value
											})
										}}
										onSubmit={this.parseCard}
									>
										{canSendPhoto && (
											<Button
												className="Home__button"
												color='quaternary'
												onClick={() => {
													this.setState({
														sendingPhoto: true
													})
												}}
											>
												{formatMessage(messages.sendPhotos)}
											</Button>
										)}
									</CardForm>
								)}
							</div>
						)}
						<Text className="Home__footer Home__footer--main" variant="caption">
							{formatMessage(messages.warning)}
						</Text>
						<Text className="Home__footer" variant="caption" paragraph>
							{`${formatMessage(messages.contact)} `}
							{contact_email
								&& <Link href={contact_email} />}
						</Text>
						<LanguageChooser
							onChange={handleChangeLanguage}
						/>
					</Grid>
					<Grid lg={4} md={4} sm={10} xs={10}>
						<div className="center">
							<Card
								className="Home__card"
								url={window.location.host}
							/>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

HomeTemplate.propTypes = {
	intl: PropTypes.object.isRequired,
	card: PropTypes.shape({
		card: PropTypes.shape({
			id: PropTypes.string
		}),
		photo_count: PropTypes.number
	}),
	organization: PropTypes.shape({
		organization: PropTypes.shape({
			name: PropTypes.string,
			web_name: PropTypes.string,
			url: PropTypes.string,
			contact_email: PropTypes.string
		}),
		loading: PropTypes.bool,
		error: PropTypes.string
	}).isRequired,
	history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	card: state.card,
	organization: state.organization
});

const mapActionsToProps = dispatch => ({
	fetchCard: (cardCode) => dispatch(fetchCard(cardCode)),
	fetchCardCustomer: (customer) => dispatch(fetchCardCustomer(customer)),
	clearMedias: () => dispatch(clearMedias()),
	clearCard: () => dispatch(clearCard()),
});

export default withRouter(connect(mapStateToProps, mapActionsToProps)(injectIntl(HomeTemplate)));
