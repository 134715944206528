import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Form from '../../Atoms/Form';
import Button from '../../Atoms/Button';
import CardInput from '../../Atoms/CardInput';

import './index.sass';

const CardForm = ({ className, loading, hasError, cardCode, buttonMessage, children, onChange, onSubmit }) => {
	const cardInput = useRef(null);

	return (
		<Form className={`CardForm ${className}`} onSubmit={onSubmit}>
			<CardInput
				className="Home__input"
				ref={cardInput}
				disabled={loading}
				value={cardCode}
				onChange={onChange}
			/>
			<Button
				className="Home__button"
				color={hasError ? 'error' : 'primary'}
				onClick={onSubmit}
				loading={loading}
			>
				{buttonMessage}
			</Button>
			{children}
		</Form>
	)
}

CardForm.propTypes = {
	className: PropTypes.string,
	cardCode: PropTypes.string,
	buttonMessage: PropTypes.string,
	loading: PropTypes.bool,
	hasError: PropTypes.bool,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func
};

CardForm.defaultProps = {
	className: '',
	cardCode: '',
	buttonMessage: '',
	loading: false,
	hasError: false,
	onChange: () => {},
	onSubmit: () => {}
};

export default CardForm;
