import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {Validator} from '../../../shared';

import './index.sass';

const Link = ({
	className, href, target, children
}) => {
	if (Validator.isURL(href, {
		protocols: ['http', 'https', 'ftp'],
		require_tld: true,
		require_protocol: true
	})) {
		return (
			<a className={`Link ${className}`} href={href} target={target}>
				{children || href}
			</a>
		);
	}

	if (Validator.isEmail(href)) {
		return (
			<a className={`Link ${className}`} href={`mailto:${href}`} target="_top">
				{children || href}
			</a>
		);
	}

	return (
		<RouterLink to={href} className="Link" target={target}>
			{children || href}
		</RouterLink>
	);
};

Link.propTypes = {
	className: PropTypes.string,
	href: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func
	]).isRequired,
	target: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

Link.defaultProps = {
	className: '',
	target: '_blank',
	children: null
};

export default Link;
