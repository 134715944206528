import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';

import './index.sass';

import Button from '../../Atoms/Button';
import Modal from '../../Atoms/Modal';
import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';
import CustomerForm from '../../Molecules/CustomerForm';
import AddressForm from '../../Molecules/AddressForm';
import PayForm from '../PayForm';

import {
	generateZip,
	finishOrder,
	saveOrderCustomer,
	saveOrderCustomerAddress
} from '../../../store/actions';
import { Order, Analytics } from '../../../shared';

class BuyModal extends React.Component {
	state = {
		editingCustomer: true,
		editingAddress: false
	}

	getMedias = () => {
		const { order: orderState } = this.props;
		const { order = {} } = orderState;
		const { items = [] } = order || {};
		return items.map((o) => o.items.map((a) => a.video ? a.video.id : a.photo.id)).flat()
	};

	finishOrder = () => {
		const { order: orderState, finishOrder } = this.props;
		const { order = {} } = orderState;
		const { id: orderId = '' } = order;

		finishOrder(orderId);
	}

	saveCustomer = (customer) => {
		const { order: orderState, organization: orgState, saveCustomer } = this.props;
		const { order } = orderState;
		const { id } = order;
		const { organization } = orgState;
		const { name: orgName } = organization;

		saveCustomer(id, customer);
		this.setState({ editingCustomer: false, editingAddress: true });
		Analytics.sendEvent('save_customer_info', 'buying', orgName);
	}

	saveAddress = (address) => {
		const { order: orderState, organization: orgState, saveAddress } = this.props;
		const { order } = orderState;
		const { id } = order;
		const { organization } = orgState;
		const { name: orgName } = organization;

		saveAddress(id, address);
		this.setState({ editingCustomer: false, editingAddress: false });
		Analytics.sendEvent('save_customer_info', 'buying', orgName);
	}

	close = () => {
		const { onClose } = this.props;

		this.setState({
			editingCustomer: true
		});

		onClose();
	}

	render() {
		const {
			className,
			isOpened,
			intl,
			order: orderState,
			organization: orgState,
			media,
			generateZip
		} = this.props;
		const { editingCustomer, editingAddress } = this.state;
		const { order = {}, loading } = orderState;
		const { id: orderId = '', completed, total, customer } = order;
		const { notSoldMedias = [] } = media;
		const totalOrderPhotos = Order.getTotalOrderPhotos(order, notSoldMedias.length);
		const { organization } = orgState;
		const { name: orgName } = organization;
		const {formatMessage} = intl;
		const messages = defineMessages({
			title: {
				id: 'buyModal.title',
				defaultMessage: 'Finalizar pedido'
			},
			confirmButton: {
				id: 'buyModal.confirmButton',
				defaultMessage: 'Enviar pedido'
			},
			closeButton: {
				id: 'buyModal.closeButton',
				defaultMessage: 'Não tenho certeza'
			},
			photosTitle: {
				id: 'buyModal.photosTitle',
				defaultMessage: 'Fotos'
			},
			photos: {
				id: 'buyModal.photos',
				defaultMessage: '{itemsQuantity, plural, one {1 foto} other {# fotos}}'
			},
			total: {
				id: 'buyModal.total',
				defaultMessage: 'Total a pagar'
			},
			titleSuccess: {
				id: 'buyModal.titleSuccess',
				defaultMessage: 'Pedido realizado'
			},
			downloadPhotos: {
				id: 'buyModal.downloadPhotos',
				defaultMessage: 'Fazer download das fotos'
			}
		});
		const getAction = () => {
			if (editingCustomer) {
				return (
					<CustomerForm
						customer={customer}
						loading={loading}
						onSave={this.saveCustomer}
						onCancel={this.close}
					/>
				);
			} else if (editingAddress) {
				return (
					<AddressForm
						address={customer?.addresses.length > 0 ? customer?.addresses[0] : {}}
						loading={loading}
						onSave={this.saveAddress}
						onCancel={() => this.setState({ editingCustomer: true, editingAddress: false })}
					/>
				);
			} else if (completed) {
				return (
					<Button
						className="BuyModal__action__button BuyModal__action__button--confirm"
						color="tertiary"
						size="medium"
						onClick={() => {
							generateZip(this.getMedias());
							Analytics.sendEvent('download_zip_photos', 'buying', orgName);
						}}
					>
						{formatMessage(messages.downloadPhotos)}
					</Button>
				);
			}

			return (
				<PayForm
					orderId={orderId}
					onPaid={this.finishOrder}
					onCancel={() => this.setState({ editingCustomer: false, editingAddress: true })}
				/>
			);
		}

		return (
			<Modal
				className={`BuyModal ${className}`}
				isOpened={isOpened}
				onClose={this.close}
			>
				<div className="BuyModal__content">
					<div className="BuyModal__title">
						<Text className="BuyModal__title__text" variant="caption">
							{
								!completed
									? formatMessage(messages.title)
									: formatMessage(messages.titleSuccess)
							}
						</Text>
					</div>
					{
						completed ? (
							<ImgSvg className="BuyModal__success" name="success" />
						) : (
							<div className="BuyModal__table">
								<div className="BuyModal__table__item BuyModal__table__item--digital">
									<div className="BuyModal__table__item__title">
										{formatMessage(messages.photosTitle)}
									</div>
									<div className="BuyModal__table__item__description">
										{ totalOrderPhotos > 0
											? formatMessage(messages.photos, { itemsQuantity: totalOrderPhotos })
											: 0
										}
									</div>
								</div>
								<div className="BuyModal__table__item BuyModal__table__item--price">
									<div className="BuyModal__table__item__title">
										{formatMessage(messages.total)}
									</div>
									<div className="BuyModal__table__item__description">
										{`R$ ${total || 0}`}
									</div>
								</div>
							</div>
						)
					}
					<div className="BuyModal__action">
						{getAction()}
					</div>
				</div>
			</Modal>
		);
	}
}

BuyModal.propTypes = {
	className: PropTypes.string,
	isOpened: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	onClose: PropTypes.func
};

BuyModal.defaultProps = {
	className: '',
	isOpened: false,
	onClose: () => {}
};

const mapStateToProps = state => ({
	order: state.order,
	media: state.media,
	organization: state.organization
});

const mapActionsToProps = dispatch => ({
	generateZip: (cardCode) => dispatch(generateZip(cardCode)),
	saveCustomer: (orderId, customer) => dispatch(saveOrderCustomer(orderId, customer)),
	saveAddress: (orderId, address) => dispatch(saveOrderCustomerAddress(orderId, address)),
	finishOrder: (orderId) => dispatch(finishOrder(orderId))
});

export default connect(mapStateToProps, mapActionsToProps)(withWidth()(injectIntl(BuyModal)));
