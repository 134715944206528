import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import {Validator} from '../../../shared';

import Form from '../../Atoms/Form';
import Input from '../../Atoms/Input';
import Text from '../../Atoms/Text';
import Link from '../../Atoms/Link';
import Button from '../../Atoms/Button';
import Select from '../../Atoms/Select';
import CheckBox from '../../Atoms/CheckBox';

import './index.sass';


const CustomerForm = ({ customer: receivedCustomer, loading, onSave, onCancel, intl }) => {
	const [customer, setCustomer] = useState(receivedCustomer || {});
	const [document, setDocument] = useState('cpf');
	const [cpfError, setCpfError] = useState(false);
	const onChangeField = (fieldName, value) => {
		const updatedCustomer = {
			...customer
		};

		updatedCustomer[fieldName] = value;

		setCustomer(updatedCustomer);
	};
	const { formatMessage } = intl;
	const messages = defineMessages({
		name: {
			id: 'customerForm.name',
			defaultMessage: 'Nome'
		},
		email: {
			id: 'customerForm.email',
			defaultMessage: 'Email'
		},
		document: {
			id: 'customerForm.document',
			defaultMessage: 'Documento'
		},
		cpf: {
			id: 'customerForm.cpf',
			defaultMessage: 'CPF'
		},
		invalidCpf: {
			id: 'customerForm.invalidCpf',
			defaultMessage: 'CPF inválido'
		},
		passport: {
			id: 'customerForm.passport',
			defaultMessage: 'Passaporte'
		},
		other: {
			id: 'customerForm.other',
			defaultMessage: 'Outro'
		},
		phone: {
			id: 'customerForm.phone',
			defaultMessage: 'Telefone'
		},
		next: {
			id: 'customerForm.next',
			defaultMessage: 'Próximo'
		},
		iAmNotSure: {
			id: 'customerForm.iAmNotSure',
			defaultMessage: 'Não tenho certeza'
		},
		acceptThe: {
			id: 'customerForm.acceptThe',
			defaultMessage: 'Aceito os'
		},
		termsOfService: {
			id: 'customerForm.termsOfService',
			defaultMessage: 'termos de serviço'
		}
	});

	return (
		<>
			<Form
				id="form"
				className="CustomerForm"
				onSubmit={(e) => {
					e.preventDefault();
					onSave(customer);
				}}
			>
				<Input
					id="customer_name"
					className="CustomerForm__input CustomerForm__input--full"
					type="text"
					label={formatMessage(messages.name)}
					value={customer?.name || ''}
					onChange={(e) => onChangeField('name', e.target.value)}
				/>
				<Input
					id="customer_email"
					className="CustomerForm__input CustomerForm__input--full"
					type="email"
					label={formatMessage(messages.email)}
					value={customer?.email || ''}
					required
					onChange={(e) => onChangeField('email', e.target.value)}
				/>
				<div className="CustomerForm__container">
					<Select
						id="document-select"
						label={formatMessage(messages.document)}
						className="CustomerForm__input CustomerForm__input--full"
						value={document}
						options={[
							{
								label: formatMessage(messages.cpf),
								value: 'cpf'
							},
							{
								label: formatMessage(messages.passport),
								value: 'passport'
							},
							{
								label: formatMessage(messages.other),
								value: 'other'
							}
						]}
						onChange={(e) => {
							setDocument(e.target.value )
							setCpfError(false)
						}}
					/>
					{document === 'cpf' && (
						<Input
							id="customer_cpf"
							className="CustomerForm__input CustomerForm__input--full"
							type="text"
							label={cpfError ? formatMessage(messages.invalidCpf) : formatMessage(messages.cpf)}
							error={cpfError === true}
							value={customer?.cpf || ''}
							required
							onChange={(e) => onChangeField('cpf', e.target.value)}
							onFocus={() => setCpfError(false)}
							onBlur={(e) => setCpfError(e.target.value && !Validator.isValidCPF(e.target.value) ? true : false)}
						/>
					)}
					{document === 'passport' && (
						<Input
							id="customer_passport"
							className="CustomerForm__input CustomerForm__input--full"
							type="text"
							label={formatMessage(messages.passport)}
							value={customer?.passport || ''}
							required
							onChange={(e) => onChangeField('passport', e.target.value)}
						/>
					)}
					{document === 'other' && (
						<Input
							id="customer_other"
							className="CustomerForm__input CustomerForm__input--full"
							type="text"
							label={formatMessage(messages.other)}
							value={customer?.other || ''}
							required
							onChange={(e) => onChangeField('other', e.target.value)}
						/>
					)}
				</div>
				<Input
					id="customer_phone"
					className="CustomerForm__input CustomerForm__input--full"
					type="phone"
					label={formatMessage(messages.phone)}
					value={customer?.phone || ''}
					required
					onChange={(e) => onChangeField('phone', e.target.value)}
				/>
				<CheckBox
					label={<Text>{formatMessage(messages.acceptThe)} <Link href="/terms" target="_blank">{formatMessage(messages.termsOfService)}</Link></Text>}
					required
					checked={customer?.accepted_terms || false}
					onChange={(checked) => onChangeField('accepted_terms', checked)}
				/>
				<div id="field_errors" />
				<Button
					className="CustomerForm__save"
					color="secondary"
					type="submit"
					loading={loading}
					disabled={cpfError}
					size="medium"
				>
					{formatMessage(messages.next)}
				</Button>
			</Form>
			{!loading && (
				<Button
					className="CustomerForm__close"
					color="primary"
					isLink
					loading={false}
					onClick={onCancel}
					size="medium"
				>
					{formatMessage(messages.iAmNotSure)}
				</Button>
			)}
		</>
	)
}

CustomerForm.propTypes = {
	customer: PropTypes.shape({
		name: PropTypes.string,
		email: PropTypes.string,
		cpf: PropTypes.string,
		passport: PropTypes.string,
		other: PropTypes.string,
		phone: PropTypes.string
	}),
	loading: PropTypes.bool,
	onCancel: PropTypes.func,
	intl: PropTypes.shape().isRequired
};

CustomerForm.defaultProps = {
	customer: {},
	loading: false,
	onError: () => {},
	onCancel: () => {}
}

export default injectIntl(CustomerForm);
