/* global fetch */

export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const fetchProductsBegin = () => ({
	type: FETCH_PRODUCTS_BEGIN
});

export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const fetchProductsSuccess = (all_digital, dinamic) => ({
	type: FETCH_PRODUCTS_SUCCESS,
	payload: { all_digital, dinamic }
});

export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const fetchProductsFailure = error => ({
	type: FETCH_PRODUCTS_FAILURE,
	payload: { error }
});

export function fetchProducts(orgId) {
	return (dispatch) => {
		dispatch(fetchProductsBegin());
		return fetch(`${process.env.ROVERPIX_API}/api/v1/products/${orgId}/online`)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(fetchProductsFailure(json.message));
				} else {
					dispatch(fetchProductsSuccess(json.all_digital, json.dinamic));
				}
			}).catch((e) => {
				dispatch(fetchProductsFailure(e.toString()));
			});
	};
}
