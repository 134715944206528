import {
	FETCH_ORGANIZATION_BEGIN,
	FETCH_ORGANIZATION_SUCCESS,
	FETCH_ORGANIZATION_FAILURE
} from '../actions';

const initialState = {
	organization: {
		name: '',
		web_name: '',
		url: '',
		contact_email: ''
	},
	loading: false,
	error: ''
};

export default function photoReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_ORGANIZATION_BEGIN:
		return {
			...state,
			loading: true,
			error: ''
		};

	case FETCH_ORGANIZATION_SUCCESS:
		return {
			...state,
			loading: false,
			organization: action.payload.organization
		};

	case FETCH_ORGANIZATION_FAILURE:
		return {
			...state,
			loading: false,
			error: action.payload.error,
			organization: {}
		};

	default:
		return state;
	}
}
