class Order {
	static getDinamicOrderItem (order) {
		const { items = [] } = order;
		const dinamicItem = items.find((item) => item.product_type.unities !== 0);

		return dinamicItem || {};
	};

	static getDinamicOrderItemPhotos (order) {
		const dinamicItem = Order.getDinamicOrderItem(order);

		return dinamicItem && dinamicItem.items ? dinamicItem.items : [];
	};

	static isMediaOnOrder (mediaId, order) {
		const { items = [] } = Order.getDinamicOrderItem(order);
		const media = items.find((item) => (item.photo && item.photo.id === mediaId) || (item.video && item.video.id === mediaId));

		return media !== undefined;
	}

	static getAssociatedItemIdFromMediaId (order, mediaId) {
		const { items = [] } = Order.getDinamicOrderItem(order);
		const { id } = items.find((item) => (item.photo && item.photo.id === mediaId) || (item.video && item.video.id === mediaId));

		return id;
	}

	static getOrderItemByProductId (order, productId) {
		const { items = [] } = order;
		return items.find((item) => item.product_type.id === productId);
	}

	static hasAllDigital (order) {
		const { items: orderItems = [] } = order;

		return orderItems.some((orderItem) => {
			const { product_type } = orderItem;
			const { unities } = product_type;

			if (unities === 0) {
				return true;
			}

			return false;
		});
	}

	static getTotalOrderPhotos (order, photosLength) {
		let hasAllDigital = Order.hasAllDigital(order);
		let total = 0;
		const { items: orderItems = [] } = order;

		orderItems.forEach((orderItem) => {
			const { items: associatedItems = [] } = orderItem;
			const { product_type } = orderItem;
			const { unities } = product_type;

			total = total + associatedItems.length;

			if (unities === 0 && !hasAllDigital) {
				hasAllDigital = true;
			}
		});

		if (hasAllDigital) {
			return photosLength;
		}

		return total
	}
}

export default Order;