import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';


const ToolTip = ({ title, children }) => (
	<Tooltip title={title}>
		{children}
	</Tooltip>
);

ToolTip.propTypes = {
	title: PropTypes.string
};

ToolTip.defaultProps = {
	title: ''
};

export default ToolTip;
